* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
